import React from "react";
import { Link } from "gatsby"
import Img from "gatsby-image"
import PostContent from "../content/postContent"
import "../../assets/scss/components/_authorCard.scss"

const AuthorCard = ({author}) => (
  <div className={'author__card'}>
    <Link to={author.slug}>
      <Img fluid={author.featuredImage?.node.localFile.childImageSharp.fluid} alt={author.title} />
      <h2 className={'title'}>{author.title}</h2>
      <PostContent className={'excerpt d-none d-lg-block'} content={author.excerpt} />
    </Link>
  </div>
)

export default AuthorCard
