import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout/layout"
import SEO from "../components/util/seo"
import AuthorCard from "../components/card/authorCard"
import PostContent from "../components/content/postContent"
import "../assets/scss/layout/pages/authors.scss"

const AuthorsPage = ({ data, path }) => {
  const authors = data.allWpAuthor.nodes || []
  const content = data.allWpPage.edges[0].node.content || ''
  const englishContent = data.allWpPage.edges[0].node.ACF.content || ''

  const secondMain = () => (
    <div className={'content'}>
      <div className={'container grid-1 grid-lg-2'}>
        <PostContent className={'paragraph'} content={content} />
        <PostContent className={'paragraph'} content={englishContent} />
      </div>
    </div>
  )

  return (
    <Layout mt={true} path={path} secondMain={secondMain()}>
      <SEO title="Books" />
      <div className={'authors grid-2 grid-lg-3'}>
        {authors.map(author => <AuthorCard key={author.id} author={author} />)}
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    allWpAuthor(filter: {ACF: {display: {eq: true}}}) {
      nodes {
        featuredImage {
          node {
            localFile {
              childImageSharp {
                fluid(sizes: "300") {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        id
        title
        slug
        excerpt
        content
      }
    }
    allWpPage(filter: {slug: {eq: "authors"}}) {
      edges {
        node {
          ACF {
            content
          }
          content
        }
      }
    }
  }
`

export default AuthorsPage

